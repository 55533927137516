import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { Routes, Route } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faBriefcase, faUser, faBuilding, faPhone,  } from '@fortawesome/free-solid-svg-icons'

const pathHome="/"
const pathHiring="/hiring"
const pathOurPeople="/ourpeople"
const pathHoursLocation="/hourslocation"
const pathContact="/contact"
// const pathCareers="/careers"
// https://developers.google.com/maps/documentation/javascript/adding-a-google-map#maps_add_map-html

const Header = () => {
  return (
    <div>
      <header>
        <Navbar bg="light" variant="light">
          <Container>
            <Navbar.Brand href="#home">
              <Image fluid
                src="/ohtxIcon.png"
              />
            </Navbar.Brand>
            {/* <Nav className="align-items-center">
              <Nav.Link href="#features">View in Chinese</Nav.Link>
              <Nav.Link href="#home">
                <Image 
                  src="/Facebook.png" 
                  width="50"
                />
              </Nav.Link>
              <Nav.Link href="#home">
                <Image 
                  src="/LinkedIn.png" 
                  width="50"
                />
              </Nav.Link>
            </Nav> */}
          </Container>
        </Navbar>
        <Navbar bg="dark" variant="dark" collapseOnSelect expand="sm">
          <Container>
            <Navbar.Brand className="d-sm-none" href="#home">Central Ohio Tax</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav id="nav2" className="justify-content-between flex-grow-1">
                <LinkContainer to={pathHome}>
                  <Nav.Link><FontAwesomeIcon icon={faHouse}/> Home</Nav.Link>
                </LinkContainer>
                <LinkContainer to={pathHiring}>
                  <Nav.Link><FontAwesomeIcon icon={faBriefcase}/> Hiring & Internship</Nav.Link>
                </LinkContainer>
                <LinkContainer to={pathOurPeople}>
                  <Nav.Link><FontAwesomeIcon icon={faUser}/> Our People</Nav.Link>
                </LinkContainer>
                <LinkContainer to={pathHoursLocation}>
                  <Nav.Link><FontAwesomeIcon icon={faBuilding}/> Hours/Location</Nav.Link>
                </LinkContainer>
                <LinkContainer to={pathContact}>
                  <Nav.Link><FontAwesomeIcon icon={faPhone}/> Contact Us</Nav.Link>
                </LinkContainer>
                {/* <LinkContainer to={pathCareers}>
                  <Nav.Link><FontAwesomeIcon icon={faUser}/> Careers</Nav.Link>
                </LinkContainer> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </div>
  )
}

const Home = () => {
  return (
    <div>
      <Container className="px-0">
        <Carousel bg="dark" variant="dark">
          <Carousel.Item>
            <Image fluid src="./Drake.jpg"/>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="./EFiller.png"/>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="./Payroll.png"/>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="./QuickBooks.png"/>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="./Bookkeeping.png"/>
          </Carousel.Item>
          <Carousel.Item>
            <Image fluid src="./YearsOfExperience.png"/>
          </Carousel.Item>
        </Carousel>
      </Container>


      <h1 className="mx-auto py-3">Central Ohio Tax Company</h1>
      <hr className="w-75 mx-auto"></hr>

      <Container>
        <Row className="text-start">
          <Col className="" md={3}>
            <h3><b>Payroll Tax</b></h3>
            <p className="pb-2">We provide Fast & Accurate payroll solutions for small and medium size businesses.</p>
            <ul className="checkmarkList">
              <li>Office Open 7 Days A Week</li>
              <li>Professional & Secure</li>
              <li>Payroll Tax E-Filing</li>
              <li>Paycheck Printing Or Direct Deposit</li>
            </ul>
          </Col>
          <Col className="" md={3}>
            <h3><b>Bookkeeping</b></h3>
            <p className="pb-2">Our professionals have been trusted by dozens of other Ohio small and medium businesses and look forward to starting businesses with you.</p>
            <ul className="checkmarkList">
              <li>More Than 20 Years of Experience</li>
              <li>Profit & Loss Statement Preparation</li>
              <li>Professional Software – QuickBooks</li>
            </ul>
          </Col>
          <Col className="" md={3}>
            <h3><b>Business Tax</b></h3>
            <p className="pb-2">Central Ohio Tax Company is run by a licensed CPA, so you can count on us to handle any tax situations your business may have.</p>
            <ul className="checkmarkList">
              <li>Business Tax Planning</li>
              <li>New Business Tax Accounts Set Up</li>
              <li>Sales Tax</li>
              <li>Business Income Tax Returns</li>
            </ul>
          </Col>
          <Col className="" md={3}>
            <h3><b>Personal Tax</b></h3>
            <p className="pb-2">We provide Fast & Accurate payroll solutions for small and medium size businesses.</p>
            <ul className="checkmarkList">
              <li>Office Open 7 Days A Week</li>
              <li>Professional & Secure</li>
              <li>Personal Tax E-Filing</li>
              <li>Paycheck Printing Or Direct Deposit</li>
            </ul>
          </Col>
        </Row>
      </Container>

      <Container className="pt-5">
        <Row>
          <Col className="w-40 pe-4">
            <table className="table table-bordered" style={{fontSize: "20px", fontWeight: "bold", fontFamily: "Times New Roman"}}>
              <thead className="table-light">
                <tr><th colSpan="4">Our Payroll Service Advantages</th></tr>
                <tr>
                    <th>SERVICES</th>
                    <th><Image src="./ohtxMini.png" className="OhtxMiniIcon"/></th>
                    <th>PAYCHEX®</th>
                    <th>ADP®</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Payroll Services & Tax E-Filing</td>
                  <td><Image src="./greenCheck.png" className="GreenCheckMark"/></td>
                  <td><Image src="./grayCheck.png" className="GrayCheckMark"/></td>
                  <td><Image src="./grayCheck.png" className="GrayCheckMark"/></td>
                </tr>
                <tr>
                  <td>Check Printing & Direct Deposit</td>
                  <td><Image src="./greenCheck.png" className="GreenCheckMark"/></td>
                <td><Image src="./grayCheck.png" className="GrayCheckMark"/></td>
                  <td><Image src="./grayCheck.png" className="GrayCheckMark"/></td>
                </tr>
                <tr>
                  <td>Quality Service Guarantee</td>
                  <td><Image src="./greenCheck.png" className="GreenCheckMark"/></td>
                  <td><Image src="./grayCheck.png" className="GrayCheckMark"/></td>
                  <td><Image src="./grayCheck.png" className="GrayCheckMark"/></td>
                </tr>
                <tr>
                  <td>IRS Authorized E-Filing</td>
                  <td><Image src="./greenCheck.png" className="GreenCheckMark"/></td>
                  <td><Image src="./grayCheck.png" className="GrayCheckMark"/></td>
                  <td><Image src="./grayCheck.png" className="GrayCheckMark"/></td>
                </tr>
                <tr>
                  <td>Open 7 Days a Week</td>
                  <td><Image src="./greenCheck.png" className="GreenCheckMark"/></td>
                  <td><Image src="./redX.png" className="RedXMark"/></td>
                  <td><Image src="./redX.png" className="RedXMark"/></td>
                </tr>
                <tr>
                  <td>Same Day Paycheck Services</td>
                  <td><Image src="./greenCheck.png" className="GreenCheckMark"/></td>
                  <td><Image src="./redX.png" className="RedXMark"/></td>
                  <td><Image src="./redX.png" className="RedXMark"/></td>
                </tr>
                <tr>
                  <td>Experienced CPA and Payroll Specialists</td>
                  <td><Image src="./greenCheck.png" className="GreenCheckMark"/></td>
                  <td><Image src="./redX.png" className="RedXMark"/></td>
                  <td><Image src="./redX.png" className="RedXMark"/></td>
                </tr>
                <tr>
                  <td>Free Business and Personal Tax Review</td>
                  <td><Image src="./greenCheck.png" className="GreenCheckMark"/></td>
                  <td><Image src="./redX.png" className="RedXMark"/></td>
                  <td><Image src="./redX.png" className="RedXMark"/></td>
                </tr>
                <tr>
                  <td>Help you acquire SBA loans</td>
                  <td><Image src="./greenCheck.png" className="GreenCheckMark"/></td>
                  <td><Image src="./redX.png" className="RedXMark"/></td>
                  <td><Image src="./redX.png" className="RedXMark"/></td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col className="text-start w-40 ps-4">
            <Row className="pb-3">
              <h3>Featured</h3>
              <p>
                Our remote check-printing system and fast payroll services will maximally ease your burden on payroll preparation and paying employees almost instantly.<br/>
                Don't hesitate to contact us and set up an account at <a title="Email Us" href="mailto:tax@ohtx.com">tax@ohtx.com</a> or (614)-798-8001. 
              </p>
            </Row>
            <Row className="pb-3">
              <h3>About Us</h3>
              <p>
                Our CPA firm provides accounting services to both businesses and individuals. We give the same attention to all clients, no matter how large or small. The accounting, tax preparation, and other financial services we provide are tailored to the unique needs of each client. This ensures the best possible results and maximized returns.
              </p>
            </Row>
            <Row className="pb-3">
              <h3>Our Commitment</h3>
              <p>
                At Central Ohio tax Company, we treat our clients with courtesy and integrity. We provide efficient, honest tax service that achieve desired results. Our years of experience and notable expertise ensure that your tax preparation is in good hands.
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <Row className="footer text-start align-items-start" gap={1}>
          <Col className="px-5" sm={12} md={6} lg={{span:5, offset:1}}>
            <h3 className="py-4">Menu</h3>
            <ListGroup variant="flush dark" className="footerList">
              <ListGroup.Item>
                <LinkContainer to={pathHome}>
                  <Nav.Link><FontAwesomeIcon icon={faHouse}/> HOME</Nav.Link>
                </LinkContainer>
              </ListGroup.Item>
              <ListGroup.Item>
                <LinkContainer to={pathHiring}>
                  <Nav.Link><FontAwesomeIcon icon={faBriefcase}/> HIRING & INTERNSHIP</Nav.Link>
                </LinkContainer>
              </ListGroup.Item>
              <ListGroup.Item>
                <LinkContainer to={pathOurPeople}>
                  <Nav.Link><FontAwesomeIcon icon={faUser}/> OUR PEOPLE</Nav.Link>
                </LinkContainer>
              </ListGroup.Item>
              <ListGroup.Item>
                <LinkContainer to={pathHoursLocation}>
                  <Nav.Link><FontAwesomeIcon icon={faBuilding}/> HOURS/LOCATION</Nav.Link>
                </LinkContainer>
              </ListGroup.Item>
              <ListGroup.Item>
                <LinkContainer to={pathContact}>
                  <Nav.Link><FontAwesomeIcon icon={faPhone}/> CONTACT US</Nav.Link>
                </LinkContainer>
              </ListGroup.Item>
              <ListGroup.Item><a className="footer-link" href="http://www.chineseohio.com/">CHINESE OHIO</a></ListGroup.Item>
            </ListGroup>
          </Col>
          <Col className="px-5" sm={12} md={6}>
            <h3 className="py-4">Our Location</h3>
            <Image fluid src="./map.png"/>
          </Col>
        </Row>

        <Row className='pt-4'>
          <p>Copyright © 2022 Central Ohio Tax Company · 2696 Billingsley Rd Columbus · OH 43235 · The United States · (614)798-8001 · tax@ohtx.com</p>
        </Row>
      </footer>
    </div>
  )
}

const HiringAndInternship = () => {
  const bottomMargins = "mb-4"
  return (
    <div className='text-start mx-5 my-4'>
      <h1 className={bottomMargins}>HIRING & INTERNSHIP</h1>
      <h4 className={bottomMargins}>ABOUT THIS JOB:</h4>
      <strong className={bottomMargins}>Tax Accountant/Part-Time Employee\Intern:</strong>
      <p>We provide tax training program for our employees and interns. This is a great opportunity for students and new graduates who want to become a CPA and are interested in gaining experiences in preparing: business income tax, personal income tax, payroll tax, financial statements, and other accounting functions.</p>
      <p>The ideal candidates should have the following qualifications:</p>
      <p>- Students currently pursuing/received a degree in accounting or related majors</p>
      <p>- Self-motivated and required minimum supervision</p>
      <hr/>
      <h4 className={bottomMargins}>ABOUT CENTRAL OHIO TAX COMPANY:</h4>
      <p>Central Ohio Tax Company is a CPA firm serving business and personal clients for more than 20 years. Our firm is seeking detail oriented students and new graduates to join our team. We have sponsored many current and former employees to receive their CPA licenses.</p>
      <hr/>
      <h4 className={bottomMargins}>OFFICE LOCATION:</h4>
      <p>2696 Billingsley Road, Columbus, Ohio 43235, United States; Email: tax@ohtx.com; Phone: 614-798-8001; Fax: 614-798-8011</p>
      <hr/>
      <h4 className={bottomMargins}>SCHOOL YEARS:</h4>
      <p>Senior, Recent Accounting and Similar Major Graduates, Masters Students, Masters Graduates</p>
      <hr/>
      <h4 className={bottomMargins}>MAJORS:</h4>
      <p>All Accounting, Finance & Finance Management, Statistics, Mathematics, and Actuarial/Risk Analysis majors</p>
      <hr/>
      <h4 className={bottomMargins}>WORK AUTHORIZATION REQUIREMENTS:</h4>
      <p>US work authorization is required, but the employer is willing to sponsor selected candidates (CPT, OPT, H-1B, Green Card)</p>
      <hr/>
      <h4 className={bottomMargins}>CONTACT INFORMATION:</h4>
      <p>Please email your resume to the attention of : Gary Chan, tax@ohtx.com; phone: 614-798-8001; fax: 614-798-8011</p>
    </div>
  )
}

const OurPeople = () => {
  const bottomMargins = "mb-4"
  return (
    <div className='my-5 w-75 mx-auto'>
      <h1>OUR PEOPLE</h1>
      <hr/>
      <Container className="w-75 text-start">
        <ListGroup variant="flush">
          <ListGroup.Item>
          <h2 className="my-4">Gary Chan, CPA, Managing Partner</h2>
          <h2 className={bottomMargins}>Sally Fang, Managing partner</h2>
          </ListGroup.Item>

          <ListGroup.Item>
            <h2>IT Managers:</h2>
            <ul>
              <li><h4 className={bottomMargins}>Shuming Chan</h4></li>
              <li><h4 className={bottomMargins}>Michael Chiou</h4></li>
              <li><h4 className={bottomMargins}>Kenny Ng</h4></li>
            </ul>
            <h2>IT Staff:</h2>
            <ul>
              <li><h4 className={bottomMargins}>Brian Ku</h4></li>

            </ul>
          </ListGroup.Item>

          <ListGroup.Item>
            <h2>Operation Team:</h2>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h4 className={bottomMargins}>Christina Chen</h4>
                <h4 className={bottomMargins}>Jiaqi Lu</h4>
                <h4 className={bottomMargins}>Xiaying Zhang</h4>
                <h4 className={bottomMargins}>Ning Li</h4>
                <h4 className={bottomMargins}>Cathy Chi</h4>
                <h4 className={bottomMargins}>PeiYan Wu</h4>
                <h4 className={bottomMargins}>Emily Lei</h4>
                <h4 className={bottomMargins}>Jing Rong</h4>
                <h4 className={bottomMargins}>Miaoxian Wu</h4>
                <h4 className={bottomMargins}>Zhong</h4>
                <h4 className={bottomMargins}>Scarlett Sun</h4>
                <h4 className={bottomMargins}>Lisa Nguyen</h4>
              </ListGroup.Item>
            </ListGroup>
          </ListGroup.Item>
        </ListGroup>
      </Container>
      <hr className="my-5"/>
      <Container className="text-left">
      <h1 className="my-3">Staff Roster</h1>
      <h3 className="text-start">Central Ohio Tax Company</h3>

      <Row xs={1} md={4} className="g-2 my-4">

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2024</Card.Title>
                <h6>Operation Team:</h6>
                <ul className="alumni-list">
                  <ol>Ming Luo</ol>
                  <ol>Jiaqi Lu</ol>
                  <ol>Liting Fan</ol>
                  <ol>Claire Wu</ol>
                  <ol>Cathy Chi</ol>
                  <ol>Kenny Ng</ol>
                  <ol>Michael Chiou</ol>
                  <ol>PeiYan Wu</ol>
                  <ol>Emily Lei</ol>
                  <ol>Jing Rong</ol>
                  <ol>Miaoxian Wu</ol>
                  <ol>Zhong</ol>
                  <ol>Scarlett Sun</ol>
                </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2023</Card.Title>
                <h6>Operation Team:</h6>
                <ul className="alumni-list">
                  <ol>Fang Ren</ol>
                  <ol>Ming Luo</ol>
                  <ol>Jiaqi Lu</ol>
                  <ol>Liting Fan</ol>
                  <ol>Claire Wu</ol>
                  <ol>Tomas Choy</ol>
                  <ol>Cathy Chi</ol>
                  <ol>Irene Cheng</ol>
                  <ol>Kenny Ng</ol>
                  <ol>Michael Chiou</ol>
                </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2022</Card.Title>
                <h6>Operation Team:</h6>
                <ul className="alumni-list">
                  <ol>Fang Ren</ol>
                  <ol>Ming Luo</ol>
                  <ol>Jiaqi Lu</ol>
                  <ol>Liting Fan</ol>
                  <ol>Claire Wu</ol>
                  <ol>Tomas Choy</ol>
                  <ol>Cathy Chi</ol>
                  <ol>Irene Cheng</ol>
                  <ol>Kenny Ng</ol>
                  <ol>Michael Chiou</ol>
                </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2021</Card.Title>
                  <h6>Operation Team:</h6>
                  <ul className="alumni-list">
                    <ol>Fang Ren</ol>
                    <ol>Cindy Guo</ol>
                    <ol>Claire Wu</ol>
                    <ol>Xiaofan Bo</ol>
                    <ol>Ning Tang</ol>
                    <ol>Xiuyuan Gai</ol>
                    <ol>Mingxun Luo</ol>
                  </ul>
                <hr/>
                <h6>Trained Interns:</h6>
                  <ul className="alumni-list">
                    <ol>Jiaqi Lu</ol>
                  </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2020</Card.Title>
                  <h6>Operation Team:</h6>
                  <ul className="alumni-list">
                    <ol>Fang Ren</ol>
                    <ol>Cindy Guo</ol>
                    <ol>Claire Wu</ol>
                    <ol>Xiaofan Bo</ol>
                    <ol>Ning Tang</ol>
                    <ol>Xiuyuan Gai</ol>
                    <ol>Mingxun Luo</ol>
                  </ul>
                <hr/>
                <h6>Trained Interns:</h6>
                  <ul className="alumni-list">
                    <ol>Jiaqi Lu</ol>
                  </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2019</Card.Title>
                  <h6>Operation Team:</h6>
                  <ul className="alumni-list">
                    <ol>Fang Ren</ol>
                    <ol>Cindy Guo</ol>
                    <ol>Xiaofan Bo</ol>
                    <ol>Ning Tang</ol>
                    <ol>Xiuyuan Gai</ol>
                    <ol>Andy Tain</ol>
                    <ol>Mingxun Luo</ol>
                    <ol>Sandy Yu</ol>
                    <ol>Revathi Mungara</ol>
                    <ol>Cui Xu</ol>
                  </ul>
                <hr/>
                <h6>Trained Interns:</h6>
                  <ul className="alumni-list">
                    <ol>Jiaqi Lu</ol>
                    <ol>Han Jiao</ol>
                  </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2018</Card.Title>
                  <h6>Operation Team:</h6>
                  <ul className="alumni-list">
                    <ol>Fang Ren</ol>
                    <ol>Yan Ren</ol>
                    <ol>Cindy Guo</ol>
                    <ol>Xiaofan Bo</ol>
                    <ol>Ning Tang</ol>
                    <ol>Xiuyuan Gai</ol>
                    <ol>Cui Xu</ol>
                    <ol>Xinwei Wu (Helen)</ol>
                  </ul>
                <hr/>
                <h6>Trained Interns:</h6>
                  <ul className="alumni-list">
                    <ol>Xintian Tang</ol>
                    <ol>Yanyuan Wu</ol>
                    <ol>Ling Chen</ol>
                    <ol>Hanliu Wu</ol>
                    <ol>Canran Qin</ol>
                  </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2017</Card.Title>
                  <h6>Operation Team:</h6>
                  <ul className="alumni-list">
                    <ol>Fang Ren</ol>
                    <ol>Yan Ren</ol>
                    <ol>Jade Huang</ol>
                    <ol>Jasmine Goh</ol>
                    <ol>Cindy Guo</ol>
                    <ol>Nono Du</ol>
                    <ol>Xiaofan Bo</ol>
                    <ol>Yifan Yau</ol>
                    <ol>PK</ol>
                  </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2016</Card.Title>
                  <h6>Operation Team:</h6>
                  <ul className="alumni-list">
                    <ol>Jiaxin Chen</ol>
                    <ol>Chunjin Gao</ol>
                    <ol>(William)</ol>
                    <ol>Jiajun Sheng (Ethan)</ol>
                    <ol>Kaining Han (Edwin)</ol>
                    <ol>Shuhua Lao</ol>
                    <ol>Shuang Su (Allison)</ol>
                    <ol>Xianda Song (Jay)</ol>
                    <ol>Yi Yang (Eva)</ol>
                  </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2015</Card.Title>
                  <h6>Operation Team:</h6>
                  <ul className="alumni-list">
                    <ol>Xinting Yuan (Tina)</ol>
                    <ol>Chunjin Gao</ol>
                    <ol>(William)</ol>
                    <ol>Xinwei Wu (Helen)</ol>
                    <ol>Chih Hsuan Su</ol>
                    <ol>Jiajun Sheng (Ethan)</ol>
                    <ol>Kaining Han (Edwin)</ol>
                    <ol>Shuhua Lao</ol>
                    <ol>Shuang Su (Allison)</ol>
                    <ol>Xianda Song (Jay)</ol>
                    <ol>Yi Yang (Eva)</ol>
                    <ol>Yinghui Yu (Rachel)</ol>
                    <ol>Yiyi Wang</ol>
                    <ol>Ziyi Zhai (April)</ol>
                  </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2014</Card.Title>
                  <h6>Operation Team:</h6>
                  <ul className="alumni-list">
                    <ol>Xiang Yao (Betty)</ol>
                    <ol>Xinting Yuan (Tina)</ol>
                    <ol>Chunjin Gao</ol>
                    <ol>(William)</ol>
                    <ol>Xinwei Wu (Helen)</ol>
                    <ol>Chih Hsuan Su</ol>
                    <ol>Jiajun Sheng (Ethan)</ol>
                    <ol>Kaining Han (Edwin)</ol>
                    <ol>Shuhua Lao</ol>
                    <ol>Shuang Su (Allison)</ol>
                    <ol>Xianda Song (Jay)</ol>
                  </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2013</Card.Title>
                  <h6>Operation Team:</h6>
                  <ul className="alumni-list">
                    <ol>Zheng Li</ol>
                    <ol>Xiang Yao (Betty)</ol>
                    <ol>Fangyuan Zhou</ol>
                    <ol>Jiajun Sheng (Ethan)</ol>
                    <ol>Ran Cao (Doris)</ol>
                    <ol>Tongyao Cheng</ol>
                    <ol>Xinting Yuan (Tina)</ol>
                    <ol>Yang Song (Carol)</ol>
                    <ol>Yixuan Guo (Wendy)</ol>
                  </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2012</Card.Title>
                  <h6>Operation Team:</h6>
                  <ul className="alumni-list">
                    <ol>Zheng Li</ol>
                    <ol>Xiang Yao (Betty)</ol>
                    <ol>Jiaqi Weng</ol>
                    <ol>Tong Zhou</ol>
                  </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2011</Card.Title>
                  <h6>Operation Team:</h6>
                  <ul className="alumni-list">
                    <ol>Waicheng Lei</ol>
                    <ol>Zheng Li</ol>
                    <ol>Jiaqi Weng</ol>
                    <ol>Tong Zhou</ol>
                    <ol>Lao Yu</ol>
                  </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="alumni-card h-100">
              <Card.Body>
                <Card.Title>2003-2010</Card.Title>
                  <h6>Operation Team:</h6>
                  <ul className="alumni-list">
                    <ol>Waicheng Lei</ol>
                    <ol>Rachael Chan</ol>
                    <ol>Lao Yu</ol>
                  </ul>
              </Card.Body>
            </Card>
          </Col>
        
      </Row>

      <hr className="my-5"/>
      <h3 className="text-start">Westerville Lucky Center</h3>
      <Row xs={1} md={4} className="g-2 my-4">

        <Col>
          <Card className="alumni-card h-100">
            <Card.Body>
              <Card.Title>2022</Card.Title>
              <h6>Operation Team:</h6>
              <ul className="alumni-list">
                <ol>Carroll Blazina</ol>
                <ol>Kevin Burdock</ol>
                <ol>Xiangchuan Chen</ol>
                <ol>Paul Valenti</ol>
              </ul>
            </Card.Body>
          </Card>
        </Col>

      </Row>
      </Container>
    </div>
  )
}

const HoursLocation = () => {
  return (
    <div className="py-5">
      <h3>Location and Hours of Operation</h3>
      <p>Central Ohio Tax Company</p>
      <p>2696 Billingsley Rd<br/>Columbus, OH 43235</p>
      <p>Phone: 614-798-8001</p>
      <p>Fax: 614-798-8011</p>
      <p>Metro Fax: 1 614-7375502</p>
      <p>Hours: 7 days a week 10:00 AM to 7:00 PM</p>
      <p>Except for national holidays:</p>
      <p>New Year's Day, Memorial Day, Independence Day, Thanksgiving Day, Christmas Day</p>
    </div>
  )
}

const Contact = () => {
  return (
    <div className="py-5">
      <h1 className="mx-auto py-3">Contact Us</h1>
      <hr className="w-75 mx-auto"></hr>
      <Row>
        <Col sm={12} md={6}>
          <h3>Contact Info</h3>
          <p>Address: 2696 Billingsley Rd, Columbus, OH 43235</p>
          <p>Phone: (614) 798-8001</p>
          <p>Fax: (614) 798-8011</p>
          <p>Email: <a href="mailto:tax@ohtx.com">tax@ohtx.com</a></p>
          <p>Hours: 7 Days a week, 10:00 AM to 7:00 PM</p>
          <p>Except for national holidays:</p>
          <p>New Year's Day, Memorial Day, Independence Day, Thanksgiving Day, Christmas Day</p>
        </Col>
        <Col sm={12} md={6}>
          <hr className="w-75 mx-auto d-block d-md-none"/>
          <h3>Direct Email Form</h3>
          <form action="https://formsubmit.co/tax@ohtx.com" method="POST">
            <input className="w-75" type="email" name="email" placeholder="Your Email Address" required/><br/>
            <input type="hidden" name="_subject" value="E:ohtx customer"/>
            <input type="hidden" name="_autoresponse" value="Message sent to tax@ohtx.com received!"/>
            <input type="hidden" name="_template" value="table"/>
            <textarea rows="5" className="w-75" type="textarea" name="message body" placeholder="Message Body" required/><br/>
            <button type="submit">Send</button><br/>
            <p className="my-2 w-75 mx-auto">(Sending will take you to a CAPTCHA page for us to avoid spam to see your email better!)</p>
          </form>
        </Col>
      </Row>
    </div>
  )
}

const RoutesPaths = () => {
  return (
    <div>
      <Routes>
        <Route exact path={pathHome} element={<Home/>}/>
        <Route exact path={pathHiring} element={<HiringAndInternship/>}/>
        <Route exact path={pathOurPeople} element={<OurPeople/>}/>
        <Route exact path={pathHoursLocation} element={<HoursLocation/>}/>
        <Route exact path={pathContact} element={<Contact/>}/>
      </Routes>
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <Header></Header>
      <RoutesPaths></RoutesPaths>
      <Footer></Footer>
    </div>
  );
}

export default App;
